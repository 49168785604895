.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 35px;
  max-height: 35px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  font-size: 25px;
  color: whitesmoke;
}

.headerIcon {
  margin-right: 30px;
  cursor: pointer;
}

.mainContent {
  font-size: calc(5px + 2vmin);
  margin-left: 10vw;
  margin-right: 10vw;
}

.linkIcon {
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
